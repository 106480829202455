import { experimental_createPersister as createPersister } from '@tanstack/query-persist-client-core'

export const usePersister = () => {
  const isDev = useRuntimeConfig().public.NODE_ENV === 'development'
  return isDev
    ? createPersister({
        storage: localStorage,
        maxAge: 1000 * 60 * 60 * 24,
      })
    : undefined
}
